import React from 'react'

import Privacy from '../components/modal/privacy'

const PrivacyPage = () => (
  <div className="container">
    <div className="mv4">
      <Privacy />
    </div>
  </div>
)

export default PrivacyPage
